import * as React from "react";
import { LivePerformances, Title, About, Contact } from "./pages";

import "./scss/layout.scss";

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Matt Gordeuk</title>
      <Title />
      <About />
      <LivePerformances />
      <Contact />
    </main>
  );
};

export default IndexPage;
