import { Social } from './components/containers'

export const Mail = () => (
  <Social link='mailto:mgordeuk@gmail.com'>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M12 2.02c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 12.55l-5.992-4.57h11.983l-5.991 4.57zm0 1.288l-6-4.629v6.771h12v-6.771l-6 4.629z"/></svg>
  </Social>
)

export const Facebook = () => (
  <Social link='https://www.facebook.com/mattgordeukmusic'>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>,
  </Social>
)

export const Youtube = (props) => (
  <Social link='https://www.youtube.com/channel/UCR-hV7FrfIyWf6UiRBffQcQ'>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M8.326 6h.721l.493 1.851.458-1.851h.727l-.832 2.752v1.878h-.716v-1.878l-.851-2.752zm3.297 1.187c-.56 0-.931.37-.931.917v1.675c0 .602.314.916.931.916.511 0 .913-.342.913-.916v-1.675c0-.535-.398-.917-.913-.917zm.266 2.563c0 .186-.095.323-.266.323-.176 0-.277-.144-.277-.323v-1.589c0-.187.086-.326.265-.326.194 0 .278.134.278.326v1.589zm2.364-2.535v2.593c-.077.097-.25.256-.374.256-.135 0-.169-.093-.169-.23v-2.619h-.635v2.855c0 .337.103.61.443.61.192 0 .459-.1.734-.426v.377h.635v-3.416h-.634zm1.101 6.929c-.224 0-.271.158-.271.382v.329h.535v-.329c.001-.221-.046-.382-.264-.382zm-2.351.02l-.125.099v2.027l.144.115c.099.05.242.054.31-.034.034-.046.052-.121.052-.224v-1.68c0-.11-.021-.193-.064-.25-.074-.096-.211-.107-.317-.053zm2.413-2.598c-1.301-.089-5.533-.088-6.833 0-1.406.097-1.572.947-1.583 3.184.011 2.233.175 3.087 1.583 3.184 1.3.088 5.532.089 6.833 0 1.407-.096 1.573-.946 1.584-3.184-.011-2.233-.175-3.087-1.584-3.184zm-6.162 5.344h-.681v-3.77h-.705v-.64h2.091v.64h-.705v3.77zm2.424 0h-.605v-.359c-.111.132-.228.233-.348.302-.326.187-.773.183-.773-.477v-2.72h.604v2.494c0 .131.032.219.161.219.118 0 .282-.151.355-.244v-2.47h.605v3.255zm2.328-.675c0 .403-.15.716-.553.716-.222 0-.406-.081-.575-.292v.25h-.61v-4.409h.61v1.42c.137-.167.322-.304.538-.304.443 0 .59.374.59.815v1.804zm2.235-.876h-1.157v.614c0 .244.021.455.265.455.255 0 .271-.172.271-.455v-.226h.622v.244c0 .627-.269 1.007-.906 1.007-.577 0-.873-.421-.873-1.007v-1.46c0-.565.373-.957.919-.957.58 0 .86.369.86.957v.828zm-4.241-13.359c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"/></svg>
  </Social>
)

export const Instagram = () => (
  <Social link='https://www.instagram.com/mattgmusic93/'>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
  </Social>
)

export const Spotify = () => (
  <Social>
    <svg viewBox="-20 -20 220 220" width="200" height="200"><path fill="white" d="M83.7 0C37.5 0 0 37.5 0 83.7c0 46.3 37.5 83.7 83.7 83.7 46.3 0 83.7-37.5 83.7-83.7S130 0 83.7 0zM122 120.8c-1.4 2.5-4.6 3.2-7 1.7-19.8-12-44.5-14.7-73.7-8-2.8.5-5.6-1.2-6.2-4-.2-2.8 1.5-5.6 4-6.2 32-7.3 59.6-4.2 81.6 9.3 2.6 1.5 3.4 4.7 1.8 7.2zM132.5 98c-2 3-6 4-9 2.2-22.5-14-56.8-18-83.4-9.8-3.2 1-7-1-8-4.3s1-7 4.6-8c30.4-9 68.2-4.5 94 11 3 2 4 6 2 9zm1-23.8c-27-16-71.6-17.5-97.4-9.7-4 1.3-8.2-1-9.5-5.2-1.3-4 1-8.5 5.2-9.8 29.6-9 78.8-7.2 109.8 11.2 3.7 2.2 5 7 2.7 10.7-2 3.8-7 5-10.6 2.8z"></path></svg>
  </Social>
)

