export const Section = ({children, style, className = 'container'}) => {
  return (
    <div className={className} style={{
      display: 'flex',
      minHeight: '100vh',
      width: '100vw',
      flexDirection: 'column',
      ...style
    }}>
      {children}
    </div>
  )
}

export const Footer = ({children}) => {
  return (
    <div className='container footer'>
      {children}
    </div>
  )
}

export const Social = ({children, link}) => {
  return <a href={link} target='_blank' rel='noreferrer'>
    <div style={{
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
      boxSizing: 'border-box'
    }}>
      {children}
    </div>
  </a>
}

export const TextBlock = ({
  style,
  children
}) => {
  return <div style={{
    ...style
  }} >
    {children}
  </div>
}
