import { Section, TextBlock } from '../components'
import { Youtube, Instagram, Mail, Facebook } from '../social'

export const Contact = () => {
  return <Section style={{
    backgroundColor: 'rgb(162, 154, 255)',
  }}>
    <h1 className='display' style={{
      fontFamily: 'Nunki Display',
      color: 'black',
    }}>Contact</h1>

    <TextBlock>
      <h2>Booking</h2>
      <p>
        <a href='mailto:mgordeuk@gmail.com' style={{textDecoration: 'underline'}}>Send an email</a> to book performances, recording sessions (remote and in person), private events, lessons, masterclasses and clinics.
      </p>
      <div style={{ display: 'flex' }}>
        <Mail />
        <p style={{ paddingLeft: 20 }}><a href='mailto:mgordeuk@gmail.com'>mgordeuk@gmail.com</a></p>
      </div>

    </TextBlock>


    <h2>Social</h2>
    <div style={{ display: 'flex', width: 220, justifyContent: 'space-between' }}>
      <Instagram />
      <Youtube />
      <Facebook />
    </div>
  </Section>
}
