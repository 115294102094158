import {
  Section
}  from '../components'

export const Title = () => {
  return <Section className='mainImage container' style={{
  }}>
    <h1 className='display' style={{
      flex: 1,
      marginTop: 40,
      marginBottom: 64,
      maxWidth: 320,
      fontFamily: 'Nunki'
    }}>
      Matt Gordeuk
    </h1>

    {/*
    <p id='about' style={{
      color: '#fff',
      paddingTop: '30%'
    }}>
      New Jersey based <span className='displaySpan' style={{color: '#ffa700'}}>GUITARIST</span> and <span className='displaySpan' style={{color: '#a29aff'}}>COMPOSER</span>.
    </p>
    */}

    <p id='about' style={{
      color: '#fff',
      paddingTop: '30%'
    }}>
      New Jersey based guitarist and composer.
    </p>

  </Section>
}
