import { TextBlock, Section } from "../components";

const LivePerformance = ({ artist, date, venue, time, location }) => {
  return (
    <TextBlock style={{ paddingTop: "40px" }}>
      <h2 style={{ margin: "20px 0 0px" }}>
          {date.toLocaleString("en-us", {
            weekday: "long",
            month: "long",
            day: "numeric",
          })}
      </h2>
      <p style={{ margin: 0 }}>{artist} </p>
      <p style={{ opacity: "0.7", margin: "20px 0 0" }}>
        <code style={{ margin: "0px" }}>{venue}</code>
      </p>
      <p style={{ opacity: "0.7", margin: "0 0" }}>
        <code style={{ margin: "0px" }}>{location}</code>
      </p>
      <p
        style={{ opacity: "0.7", margin: "0 0 0 ", textTransform: "uppercase" }}
      >
        <code style={{ margin: "0" }}>{time}</code>
      </p>
    </TextBlock>
  );
};

const MG3 = [
  {
    date: "Friday, Jan 6",
    time: "6-9pm",
    venue: "The Playhouse Deck at The Bucks County Playhouse",
    location: `New Hope, PA`,
  },
  {
    date: `Thursday, Jan 12`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Thursday, Jan 19`,
    time: `6-9pm`,
    subtitle: `Performing with Chris Giakas as The Galavant Quartet`,
    venue: `Stanton Ridge Golf & Country Club`,
    location: `White House, NJ`,
  },
  {
    date: `Friday, Jan 20`,
    time: `6-9pm`,
    venue: `The Playhouse Deck at The Bucks County Playhouse`,
    location: `New Hope, PA`,
  },
  {
    date: `Friday, February 3`,
    time: `6-9pm`,
    venue: `The Playhouse Deck at The Bucks County Playhouse`,
    location: `New Hope, PA`,
  },
  {
    date: `Friday, February 17`,
    time: `6-9pm`,
    venue: `The Playhouse Deck at The Bucks County Playhouse`,
    location: `New Hope, PA`,
  },
  {
    date: `Saturday, February 25`,
    time: `7-11pm`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Saturday, March 4`,
    time: `6-9pm`,
    venue: `The Playhouse Deck at The Bucks County Playhouse`,
    location: `New Hope, PA`,
  },
  {
    date: `Saturday, March 11`,
    time: `6-9pm`,
    venue: `The Playhouse Deck at The Bucks County Playhouse`,
    location: `New Hope, PA`,
  },
  {
    date: `Thursday, March 16`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Saturday, March 25`,
    time: `6-9pm`,
    venue: `The Playhouse Deck at The Bucks County Playhouse`,
    location: `New Hope, PA`,
  },
  {
    date: `Friday, April 14`,
    time: `6-9pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, April 29`,
    time: `7-11pm`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Thursday, May 18`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Thursday, June 29`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Saturday, July 22`,
    time: `7-11pm`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Thursday, Aug 17`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Friday, September 15`,
    time: `6-9pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Thursday, Sep 21`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Thursday, Oct 19`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Saturday, Oct 28`,
    time: `6-9pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Thursday, Nov 16`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
  {
    date: `Thursday, Dec 28`,
    time: `6:30-10`,
    venue: `Bowman’s Tavern`,
    location: `New Hope, PA`,
  },
].map((e) => ({
  ...e,
  artist: "MG3",
}));

const DuoWithIan = [
  {
    date: `Friday, Jan 13`,
    time: `9pm-12am`,
    venue: `Roof at Odette’s`,
    location: `New Hope, PA`,
  },
  {
    date: `Friday, Feb 24`,
    time: `9pm-12am`,
    venue: `Roof at Odette’s`,
    location: `New Hope, PA`,
  },
  {
    date: `Friday, March 10`,
    time: `9pm-12am`,
    venue: `Roof at Odette’s`,
    location: `New Hope, PA`,
  },
  {
    date: `Friday, April 21`,
    time: `9pm-12am`,
    venue: `Roof at Odette’s`,
    location: `New Hope, PA`,
  },
].map((e) => ({
  ...e,
  artist: "Matt Gordeuk w/ Ian Kenselaar (Duo)",
}));

const Solo = [
  {
    date: `Saturday, Jan 7`,
    time: `6-10pm`,
    venue: `Water Wheel Tavern`,
    location: `Doylestown, PA`,
  },
  {
    date: `Saturday, March 11`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, March 18`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, March 25`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, April 8`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, April 22`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, April 29`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, May 6`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Wednesday, May 10`,
    time: `5:30-8:30pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, May 20`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Friday, June 2`,
    time: `6-9pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, July 15`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, July 22`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Wednesday, July 26`,
    time: `5:30-8:30pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, Aug 5`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, Aug 12`,
    time: `6-9pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, Sep 2`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, Sep 30`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, Oct 7`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
  {
    date: `Saturday, Nov 4`,
    time: `12-3pm`,
    venue: `Under The Moon`,
    location: `Lambertville, NJ`,
  },
].map((e) => ({ ...e, artist: "Matt Gordeuk (Solo)" }));
const now = new Date();

const future = new Date();
future.setMonth(now.getMonth() + 3)

const events = [...MG3, ...DuoWithIan, ...Solo]
  .map((e) => ({ ...e, date: new Date(e.date + " 2023") }))
  .sort((a, b) => a.date - b.date)
  .filter((e) => e.date >= now && e.date < future);

export const LivePerformances = () => {
  return (
    <Section
      style={{
        color: "black",
        backgroundColor: "#ffc961",
      }}
    >
      <h1
        className="display"
        style={{ color: "#rgb(49, 15, 42)", fontFamily: "Nunki Display" }}
      >
        Live Performances
      </h1>

      {events.map((data, i) => (
        <LivePerformance key={i} {...data} />
      ))}
    </Section>
  );
};
